<template>
  <default-layout>
    <div v-if="isLoading">Loading account information...</div>
    <PriceSelection v-else-if="!subscription" />
    <SubscribedAccount v-else :subscription="subscription"/>
    <div class="flex flex-col items-center">
      <button class="btn btn-primary w-fit" 
              :disabled="customerPortalLoading"
              @click="openCustomerPortal">
        {{ customerPortalLoading ? "Loading..." : "Open my billing portal" }}
      </button>      
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout";
import PriceSelection from "@/components/PriceSelection";
import SubscribedAccount from "@/components/SubscribedAccount";
import { ref, onMounted } from "vue";
import { firebaseAuth, firebaseFunctions } from "@/firebase";
import { httpsCallable } from "firebase/functions";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  limit,
} from "firebase/firestore";

export default {
  components: {
    DefaultLayout,
    PriceSelection,
    SubscribedAccount,
  },
  setup() {
    const isLoading = ref(false);
    const subscription = ref(null);

    const fetchSubscription = async () => {
      isLoading.value = true;
      const db = getFirestore();
      const subsRef = collection(
        db,
        "customers",
        firebaseAuth.currentUser.uid,
        "subscriptions"
      );
      const subsQuery = query(
        subsRef,
        where("status", "in", ["trialing", "active", "past_due"]),
        limit(1000)
      );
      subscription.value = await getDocs(subsQuery).then((sub) =>
        sub.docs.length > 0 ? sub.docs[0].data() : null
      );
      isLoading.value = false;
    };

    const customerPortalLoading = ref(false)
    const openCustomerPortal = async () => {
      customerPortalLoading.value = true
      
      // const functions = getFunctions(firebaseApp, "australia-southeast1")
      const functionRef = httpsCallable(
        firebaseFunctions,
        "ext-firestore-stripe-payments-createPortalLink"
      )
      const { data } = await functionRef({
        returnUrl: window.location.origin,
      })
      window.location.assign(data.url)
    }

    onMounted(() => {
      fetchSubscription();
    });

    return {
      isLoading,
      subscription,
      customerPortalLoading,
      openCustomerPortal
    };
  }
};
</script>