<template>
  <div class="min-h-screen flex flex-col text-white bg-theme-tertiary">
    <router-view class="flex-grow" />
  </div>
</template>

<script>
// import { firebaseApp } from './firebase';
// import { getMessaging, onMessage } from "firebase/messaging";

// const messaging = getMessaging(firebaseApp);
// onMessage(messaging, (payload) => {
//   console.log('Message received. ', payload);
//   // ...
// });
</script>

<style scoped>
@font-face {
  font-family: 'WimpyKidCured'; /* You can choose a suitable name */
  src: url('assets/fonts/WimpyKidCured.otf') format('opentype');
}
</style>