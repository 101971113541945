<template>
  <div class="flex flex-col items-center justify-center m-3">
      <h1 v-html="randomMessage" class="text-lg"></h1>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useStateStore } from '@/stores/StateStore';
import { getVideoCountForDate } from '@/firebase';

export default {
  props: ["date", "tailgaters"],
  setup(props) {
    const selectedLocationId = ref('')
    const totalEvents = ref(0);

    const messages = [
      "Psst! From %replaceTotalEvents% entries and exits, we've got %replaceTailgaters% potential tailgate buddies. 🚶‍♂️ Take a peek below and help us spot the sneaky ones!",

      "Hey, detective! Out of %replaceTotalEvents% comings and goings, %replaceTailgaters% might've tried a tailgate move. 🎩 Have a gander below and confirm the culprits for us!",

      "Woah! Among %replaceTotalEvents% ins and outs, %replaceTailgaters% are raising our tailgate alarms. 🔍 Lend us your eyes and see if they're legit!",

      "Heads up! From %replaceTotalEvents% entrances and exits, we've got %replaceTailgaters% on the tailgate radar. 🧐 Dive in below and tell us if we're on to something!",

      "Alert! Out of %replaceTotalEvents% move-arounds, %replaceTailgaters% might be cheeky tailgaters. 🚧 Check 'em out and give us the thumbs up (or down)!",

      "Hold the door! Out of %replaceTotalEvents% entries, %replaceTailgaters% are looking a tad tailgate-y. 🚪 Scroll down and play 'Spot the Suspect' with us!",

      "Quick question! From %replaceTotalEvents% hustle and bustle, we think %replaceTailgaters% might've been tailgate tricksters. 🎭 Can you spot who's genuine?",

      "On the tailgate trail! Of %replaceTotalEvents% ins and outs, %replaceTailgaters% seem fishy. 🐟 Dive deep below and let us know if we've caught something!",

      "Hey, partner! Out of %replaceTotalEvents% comings and goings, we're side-eyeing %replaceTailgaters% of them. 👀 Help us figure out who's playing sneaky!",

      "Just in! From %replaceTotalEvents% entrances, %replaceTailgaters% are potential tailgate party crashers. 🎉 Party's below, help us know who to let in!",

      "Yo, detective! %replaceTotalEvents% moves made, and %replaceTailgaters% are on our maybe-tailgate list. 📜 Give it a glance and separate fact from fiction!",

      "Tailgate alert! From %replaceTotalEvents% entries, %replaceTailgaters% might be the mystery guests. 🔦 Spotlight's on them below; confirm the invitees for us!",

      "Whoops! Out of %replaceTotalEvents% entries, %replaceTailgaters% might've tried a quick one. 🏃💨 Speed-read below and tell us if any slipped by!",

      "Hey, Sherlock! Out of %replaceTotalEvents% passersby, we've got %replaceTailgaters% potential tailgate mysteries. 🔍 Unravel the clues below!",

      "Door drama alert! %replaceTotalEvents% people popped in, and %replaceTailgaters% seem to have some tailgate vibes. 🚨 Dive into the drama below and give us the scoop!",
    ]

    const getRandomStringWithSpan = () => {
        const randomString = messages[Math.floor(Math.random() * messages.length)];
        const withTotalEvents = randomString.replace(/%replaceTotalEvents%/g, `<span style="font-weight: bold; font-size: larger;">${totalEvents.value}</span>`);
        const finalString = withTotalEvents.replace(/%replaceTailgaters%/g, `<span style="font-weight: bold; font-size: larger;" class="underline">${props.tailgaters}</span>`);
        return finalString
    };

    const randomMessage = computed(() => getRandomStringWithSpan());

    onMounted(async() => {
      const stateStore = useStateStore();
      selectedLocationId.value = stateStore.getSelectedLocationId();

      totalEvents.value = await getVideoCountForDate(selectedLocationId.value, props.date);      
    });


    return {
      totalEvents,
      randomMessage
    }
  }
};
</script>