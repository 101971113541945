<template>
  <div class="bg-blue-950 mt-5">
    <h2 v-if="loading">Loading...</h2>
      
    <div class="grid-container">
      <div v-for="item, index in flaggedVidData" :key="item.videoPath">
        <div class="grid-item bg-transparent p-0 flex flex-col items-center">
          <VideoSection
          :index="index"
          :videoId="item.videoId"
          :vidPath="item.videoPath"
          :metadata="item"
          :width="width"
          :height="height"
          :removeVidFromView="removeVidFromView"/>
        </div>
      </div>
    </div>

    <LoadMoreVideos
      :isLoading="loading"
      :isEmpty="!flaggedVidData.length"
      :hasReachedEnd="reachedEnd"
      messageOnEmpty="No videos to show"
      :loadMore="loadMore"
    ></LoadMoreVideos>

  </div>
</template>

<script>
import { ref, reactive, onMounted, onBeforeUnmount } from "vue";
import { useStateStore } from '@/stores/StateStore';

import {
  onSnapshot,
  where,
  query,
  limit,
  orderBy,
  getDocs,
  startAfter,
} from "firebase/firestore";
import LoadMoreVideos from '../animatedComponents/LoadMoreVideos.vue';
import VideoSection from './VideoSection.vue';
import { getVideoDocRef, getVideoCollectionRef, getFlaggedVideoCountForDate } from "@/firebase";

export default {
  props: ["date", "width", "height", "getVideoCount"],
  components: {
    LoadMoreVideos,
    VideoSection,
},
  setup(props) {
    const stateStore = useStateStore();
    const selectedLocationId = ref();
    selectedLocationId.value = stateStore.userLocations[stateStore.selectedLocation];

    const year = props.date.getFullYear();
    const month = String(props.date.getMonth() + 1).padStart(2, '0');
    const day = String(props.date.getDate()).padStart(2, '0');
    const dayStart = new Date(year, month - 1, day, 0, 0, 0, 0);
    const dayEnd = new Date(year, month - 1, day, 23, 59, 0, 0);

    const flaggedVidData = reactive([]);
    const flaggedVidIds = [];
    const loading = ref(false);
    
    const flaggedVidCount = ref(0);
    const paginateAmount = 6;
    const reachedEnd = ref(false);

    const dataRef = getVideoCollectionRef(selectedLocationId.value);

    let lastVisible = null;
    const baseLoadFunction = async (q) => {
      try {
        const querySnapshot = await getDocs(q);
        
        querySnapshot.forEach((doc) => {
          const docData = doc.data();
          const videoId = doc.id;
          const docRef = getVideoDocRef(selectedLocationId.value, videoId);

          const vidValue = {  
                              "videoId": videoId,
                              "videoPath": docData.path,
                              "docRef": docRef,
                              "userReviewed" : docData.user_reviewed,
                              "checkedReport" : docData.checked_report,
                              "suspects": docData.suspects,
                              "entry_time": docData.entry_time,
                              "b2_bucket": docData.b2_bucket,
                              "b2_path": docData.b2_path,
                            }

          flaggedVidData.push(vidValue);
        });
        lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        reachedEnd.value = querySnapshot.size == 0; // Reached end of flagged videos
      } catch (error) {
        console.error('Error loading more items:', error);
      } 
      
    };

    const loadInitialDone = ref(false);
    const loadInitial = async () => {
      const dataQuery = query(
        dataRef, 
        where("entry_time", ">=", dayStart),
        where("entry_time", "<=", dayEnd),
        where("flagged", "==", true),
        orderBy("entry_time", "desc"),
        limit(paginateAmount)
      );

      loading.value = true;
      await baseLoadFunction(dataQuery);
      loading.value = false;

      loadInitialDone.value = true;
    }
    const loadMore = async () => {
      if (lastVisible) {
        const dataQueryStartAfter = query(
              dataRef, 
              where("entry_time", ">=", dayStart),
              where("entry_time", "<=", dayEnd),
              where("flagged", "==", true),
              orderBy("entry_time", "desc"),
              startAfter(lastVisible),
              limit(paginateAmount)
            );
        loading.value = true;
        await baseLoadFunction(dataQueryStartAfter);
        loading.value = false;        
      }
      // Otherwise no other videos to load
    }

    const handleSnapshot = async (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const docData = change.doc.data();
        const videoId = change.doc.id;
        const docRef = getVideoDocRef(selectedLocationId.value, videoId);

        const videoPath = docData.path;
        
        const vidNotDisplayed = flaggedVidIds.indexOf(videoId) == -1;
        const targetChangeType = change.type === 'added' || change.type === 'modified'
        if (targetChangeType && vidNotDisplayed) {
          const vidValue = {  
                              "videoId": videoId,
                              "videoPath": videoPath,
                              "docRef": docRef,
                              "userReviewed" : docData.user_reviewed,
                              "checkedReport" : docData.checked_report,
                              "suspects": docData.suspects,
                              "entry_time": docData.entry_time,
                            }

          // Update occurs during start of day when no videos are present
          if (flaggedVidData.length == 0) {
            if (loadInitialDone.value) {
              flaggedVidIds.push(videoId);
              flaggedVidData.push(vidValue);
            } 
          }
          // Update occurs when some videos have already been loaded
          else if (docData.entry_time.toDate() > flaggedVidData[0].entry_time.toDate()) {
            flaggedVidIds.unshift(videoId);
            flaggedVidData.unshift(vidValue);
          }

        } else if (change.type === 'removed') {
          removeVidFromView(videoPath, videoId);
        }

      });
      flaggedVidCount.value = await getFlaggedVideoCountForDate(selectedLocationId.value, props.date)
      props.getVideoCount(flaggedVidCount.value);

      // lastVisible = snapshot.docs[snapshot.docs.length - 1];
    };

    const handleScroll = async () => {
        const scrollThreshold = 350; // Adjust as needed
        const scrollPosition = window.innerHeight + window.scrollY;
        const pageHeight = document.documentElement.scrollHeight;
        if (!loading.value && scrollPosition >= pageHeight - scrollThreshold) {
          await loadMore();
        }
    };
    
    onMounted(async () => {
      // Subscribe to Firestore collection changes when the component is mounted
      const q = query(
        dataRef, 
        where("entry_time", ">=", dayStart),
        where("entry_time", "<=", dayEnd),
        where("flagged", "==", true),
        orderBy("entry_time", "desc"),
      );
      const unsubscribe = onSnapshot(q, handleSnapshot);
      onBeforeUnmount(unsubscribe);

      await loadInitial();

      window.addEventListener("scroll", handleScroll);
    });

    onBeforeUnmount(() => {
        window.removeEventListener("scroll", handleScroll);
    });

    const removeVidFromView = (vidPath, videoId) => {
      const index = flaggedVidData.findIndex(video => video.videoPath === vidPath);
      if (index !== -1) {
        flaggedVidData.splice(index, 1);
      }

      // Remove videoId from array
      const indexId = flaggedVidIds.indexOf(videoId);
      if (indexId !== -1) { // only splice array when item is found
        flaggedVidIds.splice(indexId, 1);
      }
    }

    return {
      removeVidFromView,
      flaggedVidData,
      loading,
      reachedEnd,
      loadMore,
    }
  }
};
</script>

<style>
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  text-align: center;
}
@media (max-width: 1050px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
