import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

const pinia = createPinia()
const vueApp = createApp(App)
vueApp.config.devtools = true // !!! ATTENTION remove in production
vueApp.use(router)
vueApp.use(pinia)
vueApp.mount('#app')
vueApp.component('VueDatePicker', VueDatePicker);