<template>
  <div>
    <input type="file" ref="imageUpload" @change="handleImageUpload">
    <canvas ref="canvasElement" class="max-w-full"></canvas>
    
    <div class="flex">
      <button class="p-2 m-4 bg-blue-500 text-white rounded hover:bg-blue-700" @click="savePolygonData">Save Polygon Data</button>
      <button class="p-2 m-4 bg-blue-500 text-white rounded hover:bg-blue-700" @click="saveImage" >Save as Image</button>
      <button class="p-2 m-4 bg-blue-500 text-white rounded hover:bg-blue-700" @click="downloadPolygonData">Download Polygon Data</button>     
    </div>

  </div>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  name: 'PolygonDrawer',
  props: ["handlePolygonData"],
  setup(props) {
    const canvasElement = ref(null);
    const imageUpload = ref(null);
    let ctx;
    let image = ref(null);
    let isDrawing = ref(false);
    let polygons = ref([]);
    let segments = ref([]);

    const handleImageUpload = event => {
      const reader = new FileReader();

      reader.onload = function() {
        console.log("Reader loaded.");  // Debug log
        image.value = new Image();
        
        image.value.onload = function() {
          console.log("Image loaded.");  // Debug log
          canvasElement.value.width = image.value.width;
          canvasElement.value.height = image.value.height;

          ctx.drawImage(image.value, 0, 0);
          //URL.revokeObjectURL(blobURL); // Needed if using blob url method
        };

        // const blobURL = URL.createObjectURL(event.target.files[0]);
        // image.value.src = blobURL;

        image.value.src = reader.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    };

    const handleCanvasClick = event => {  
      isDrawing.value = true;
      const scaleX = canvasElement.value.width / canvasElement.value.offsetWidth;
      const scaleY = canvasElement.value.height / canvasElement.value.offsetHeight;

      const rect = canvasElement.value.getBoundingClientRect();
      let x = (event.clientX - rect.left) * scaleX;
      let y = (event.clientY - rect.top) * scaleY;

      if (segments.value.length > 1) {
        const firstPoint = segments.value[0][0];
        const distance = Math.sqrt((x - firstPoint.x) ** 2 + (y - firstPoint.y) ** 2);
        // Radius from origin before polygon is closed
        if (distance <= 20) {
          isDrawing.value = false;
          x = firstPoint.x;
          y = firstPoint.y;
          const segment = segments.value[segments.value.length - 1];
          segment[1] = { x, y };
          drawCompleteSegments(segments.value);
          polygons.value.push(segments.value);
          promptUser();
          segments.value = [];
        } else {
          segments.value.push([{ x, y }]);
        }
      } else {
        segments.value.push([{ x, y }]);
      }
    };

    const areaNames = ref([])
    const promptUser = () => {
      let name = window.prompt("Area name:", "");
      if (name == null || name === "") {
        alert("You must give an area name")
        promptUser();
      } else {
        areaNames.value.push(name);
      }
    };

    const handleCanvasMouseMove = event => {
      if (isDrawing.value) {
        const scaleX = canvasElement.value.width / canvasElement.value.offsetWidth;
        const scaleY = canvasElement.value.height / canvasElement.value.offsetHeight;

        const rect = canvasElement.value.getBoundingClientRect();
        let x = (event.clientX - rect.left) * scaleX;
        let y = (event.clientY - rect.top) * scaleY;
        
        const segment = segments.value[segments.value.length - 1];
        segment[1] = { x, y };
        drawIncompleteSegments();
        drawCompleteSegments(segments.value);
        drawCompletePolygons();
      }
    };

    const drawIncompleteSegments = () => {
      ctx.drawImage(image.value, 0, 0);
      ctx.strokeStyle = 'red';
      ctx.lineWidth = 4;
      const segment = segments.value[segments.value.length - 1];
      if (segment) {
        ctx.beginPath();
        ctx.moveTo(segment[0].x, segment[0].y);
        ctx.lineTo(segment[1].x, segment[1].y);
        ctx.stroke();
      }
    };

    const drawCompleteSegments = polygon_segments => {
      ctx.drawImage(image.value, 0, 0);
      ctx.strokeStyle = 'red';
      ctx.lineWidth = 4;
      for (let i = 0; i < polygon_segments.length; i++) {
        const segment = polygon_segments[i];
        ctx.beginPath();
        ctx.moveTo(segment[0].x, segment[0].y);
        ctx.lineTo(segment[1].x, segment[1].y);
        ctx.stroke();
      }    
    };

    const drawCompletePolygons = () => {
      for(let i = 0; i < polygons.value.length; i++){
        const polygon = polygons.value[i];
        ctx.strokeStyle = 'red';
        ctx.lineWidth = 4;
        for (let j = 0; j < polygon.length; j++) {
          const segment = polygon[j];
          ctx.beginPath();
          ctx.moveTo(segment[0].x, segment[0].y);
          ctx.lineTo(segment[1].x, segment[1].y);
          ctx.stroke();
        }  
      }
    };

    const saveImage = () => {
      const dataUrl = canvasElement.value.toDataURL();
      const link = document.createElement('a');
      link.download = 'polygon.png';
      link.href = dataUrl;
      link.click();
    };

    const parseRawPolygonData = () => {
      let counter = 0;
      const areas = {}
      for (const polygon of polygons.value){
        const polygonPoints = [];
        for (const segment of polygon){
          polygonPoints.push([segment[1].x, segment[1].y])          
        }
        const areaName = areaNames.value[counter];
        areas[areaName] = polygonPoints;
        counter++;
      }
      return areas;
    }

    const savePolygonData = () => {
      const areas = parseRawPolygonData();
      const data = JSON.stringify(areas);
      props.handlePolygonData(data);
    };

    const downloadPolygonData = () => {
      const areas = parseRawPolygonData();
      const data = JSON.stringify(areas);
      const link = document.createElement('a');
      link.download = 'polygon.json';
      link.href = 'data:text/json;charset=utf-8,' + encodeURIComponent(data);
      link.click();
    };

    onMounted(() => {
      ctx = canvasElement.value.getContext('2d');
      canvasElement.value.addEventListener('click', handleCanvasClick);
      canvasElement.value.addEventListener('mousemove', handleCanvasMouseMove);
    });

    return {
      canvasElement,
      imageUpload,
      handleImageUpload,
      saveImage,
      savePolygonData,
      downloadPolygonData,
    };
  },
};
</script>