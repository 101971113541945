<template>
  <div class="bg-blue-950 mt-5">   
    <div v-for="suspectVideosItem, index in visibleGridData" :key="suspectVideosItem.card_number">
      <CardShareSection
      :index="index"
      :multiEntryDocRef="multiEntryDocRef"
      :suspectId="visibleGridIds[index]"
      :suspectVideosItem="suspectVideosItem"
      :reviewed="multiEntryReviewed"
      :removeGridFromView="removeGridFromView"
      :date="date"
      :width="width"
      :height="height"/>
    </div>

    <LoadMoreVideos
      :isLoading="loading"
      :isEmpty="!visibleGridData.length"
      :hasReachedEnd="reachedEnd"
      messageOnEmpty="Make sure you've uploaded a door report for this day"
      :loadMore="loadVideos"
    ></LoadMoreVideos>

  </div>
</template>

<script>
import { ref, reactive, onMounted, onBeforeUnmount } from "vue";
import { useStateStore } from '@/stores/StateStore';

import {
  getDoc,
} from "firebase/firestore";
import LoadMoreVideos from '../animatedComponents/LoadMoreVideos.vue';
import {getMultiEntryDocRef} from "@/firebase";
import CardShareSection from './CardShareSection.vue';

export default {
  props: ["date", "width", "height", "getVideoCount"],
  components: {
    LoadMoreVideos,
    CardShareSection,
},
  setup(props) {
    const multiEntryDocRef = ref(null);
    const multiEntryVideos = ref(null);
    const suspectCardNumbers = ref(null);
    const multiEntryReviewed = ref(null);

    const stateStore = useStateStore();
    const selectedLocationId = ref();
    selectedLocationId.value = stateStore.userLocations[stateStore.selectedLocation];

    const visibleGridData = reactive([]); // grid data with entry info for suspect
    const visibleGridIds = []; // corresponding Suspect card numbers
    const loading = ref(false);
    
    const paginateAmount = 2;
    const reachedEnd = ref(false);

    let lastVisible = -1;
    const loadVideos = async () => {
      if (!reachedEnd.value && suspectCardNumbers.value.length > 0) {
        loading.value = true;

        for (let step = 0; step < paginateAmount; step++) {
          reachedEnd.value = lastVisible >= suspectCardNumbers.value.length - 1 ;
          if (!reachedEnd.value) {
            lastVisible++
            const suspectCardNumber = suspectCardNumbers.value[lastVisible]
            const suspectVideos = multiEntryVideos.value[suspectCardNumber]
            visibleGridData.push(suspectVideos)
            visibleGridIds.push(suspectCardNumber)

            // suspectVideos is an object with timestamp keys and each has a value of this:
            // {            
            //   vid_id:
            //   vid_path:
            //   suspects:  { card_number: 771354
            //                 door: "CHATSWOOD SIDE DOOR"
            //                 door_id: "pk8h9wz46h38kph7brt"
            //                 name: "Jahan Peiris"// scan_time: Object { seconds: 1709348020, nanoseconds: 0 } 
            //                 vid_id: x6lgbulxqvUqgfrBlgWy
            //                 vid_path: "gs://production-tailgating.appspot.com/clips/H72ySe7MriqQqZb1Fv8F/2024_03_02/20240302T135326.mp4"
            //               }

            // }         
          }

        }
        loading.value = false;        
      }
      // Otherwise no other videos to load
    }

    const handleScroll = async () => {
        const scrollThreshold = 350; // Adjust as needed
        const scrollPosition = window.innerHeight + window.scrollY;
        const pageHeight = document.documentElement.scrollHeight;
        if (!loading.value && scrollPosition >= pageHeight - scrollThreshold) {
          await loadVideos();
        }
    };
    
    onMounted(async () => {
      multiEntryDocRef.value = getMultiEntryDocRef(selectedLocationId.value, props.date);
      const multiEntryDocSnap = await getDoc(multiEntryDocRef.value);
      
      if (multiEntryDocSnap.exists()) {
        multiEntryVideos.value = multiEntryDocSnap.data().suspects;
        multiEntryReviewed.value = multiEntryDocSnap.data().user_reviewed;
        suspectCardNumbers.value = Object.keys(multiEntryVideos.value);

        await loadVideos();
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }

      window.addEventListener("scroll", handleScroll);

    });

    onBeforeUnmount(() => {
        window.removeEventListener("scroll", handleScroll);
    });

  
    const removeGridFromView = (gridId) => {
      const index = visibleGridIds.indexOf(gridId);
      if (index !== -1) {
        visibleGridData.splice(index, 1); // Remove gridData from array
        visibleGridIds.splice(index, 1);  // Remove gridId from array
      }
    }

    return {
      removeGridFromView,
      visibleGridData,
      visibleGridIds,
      multiEntryReviewed,
      multiEntryDocRef,
      loading,
      reachedEnd,
      loadVideos,
    }
  }
};
</script>

<style>
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  text-align: center;
}
@media (max-width: 1050px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
