import { S3Client, GetObjectCommand} from '@aws-sdk/client-s3';
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

const createSignedUrl = async(bucketName, keyName) => {
    if (process.env.VUE_APP_S3_BUCKET_NAME != bucketName) {
        // Only have credentials for VUE_APP_S3_BUCKET_NAME, so any attempt to access any other bucket will fail
        throw "Incorrect bucket credentials"
    }
    const client = new S3Client({
        endpoint: process.env.VUE_APP_S3_ENDPOINT,
        region: process.env.VUE_APP_S3_REGION,
        credentials: {
            accessKeyId: process.env.VUE_APP_S3_ACCESS_KEY_ID,
            secretAccessKey: process.env.VUE_APP_S3_SECRET_ACCESS_KEY,
        }
    });

    // var bucketName = 'somerandobucket';
    // var keyName = 'clips_cqoYkzTe7Kb87FjAFn6V_2024_06_15_20240615T105147_20240615T105211.mp4';

    const command = new GetObjectCommand({ 
        Bucket: bucketName,
        Key: keyName,
    });

    // Use this to inspect the preflight headers in the OPTIONS request to debug CORS issues.
    // When below is run, the Network tab of browser will show an OPTIONS request and the Access-Control-Request-Headers
    // Refer to README S3 Bucket Setup Section
    // const response = await client.send(command);

    return getSignedUrl(client, command, { expiresIn: 3600 });
}

export {
    createSignedUrl,
}
