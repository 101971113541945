<template>
  <div>
    <NavBar class="z-50"/>
    <!-- <h1 class="text-3xl font-semibold">Detect Unauthorised Entry</h1>
    <p>AI security uses state of the art computer vision software to help businesses keep unwanted visitors out of their premises</p>
    <p> Why door access control is not enough...</p>
    <p> Applications include analysing movement patterns to find tailgaiters</p> -->
    
    <div class="z-0">
      <div class="relative h-full overflow-hidden">
        <video ref="videoBannerRef" muted loop>
          <source src="../assets/demo/2ppl.mp4">
        </video>

        <div class="absolute inset-0 w-full h-full z-20 flex flex-col justify-center">
          <div class="font-montserrat text-white text-2xl sm:text-5xl p-2 pb-0 sm:pb-2 bg-opacity-50 bg-black rounded">
            Stop Tailgaters.
          </div>
          <div class="font-montserrat text-white text-2xl sm:text-5xl p-2 pt-0 sm:pt-2 bg-opacity-50 bg-black rounded">
            Protect Revenue.
          </div>
          <div class="text-white text-sm sm:text-lg p-2 bg-opacity-50 bg-black rounded">
            Fusorlabs Automatic Tailgating Detection protects your gym from unauthorized access
          </div>
        </div>
      </div>      
    </div>


    <div class="p-10 flex flex-col space-y-3">     
      <h1 class="font-poppins text-xl">Fusorlabs uses Artificial Intelligence to detect tailgaters and delivers those incidents to the dashboard.</h1>
      <h1 class="font-poppins text-xl">Our dashboard provides a streamlined and painless workflow for identifying tailgaters.</h1>

      <div>
        <h1 class="font-poppins p-5">Review incidents in the dashboard</h1>
        <div class="flex justify-center">
          <video ref="reviewDemoVideoRef" muted loop class="w-full sm:w-4/5 border-white border-2">
            <source src="../assets/demo/review_demo.mp4">
          </video>        
        </div>        
      </div>

      <div>
        <h1 class="font-poppins p-5">then upload door report...</h1>
        <div class="flex justify-center">
          <video ref="reportUploadVideoRef" muted loop class="w-full sm:w-4/5 border-2">
            <source src="../assets/demo/upload_report_demo.mp4">
          </video>        
        </div>        
      </div>

      <div>
        <h1 class="font-poppins p-5">To effortlessly identify members</h1>
        <div class="flex justify-center">
          <img src="../assets/demo/id_members_demo.png" alt="" class="w-full sm:w-4/5 border-2">
        </div>        
      </div>

      <div class="flex justify-center pt-16">
        <div class="justify-center space-y-5">
          <h1 class="font-poppins text-xl">Ready to stop tailgaters? Send your business enquiry to</h1>
          <div class="flex justify-center">
            <h1 class="font-poppins text-l">hello@fusorlabs.com</h1>
          </div>        
        </div>        
      </div>

      <div class="flex-row justify-center pt-16">
        <div class="justify-center space-y-2">
          <h1 class="font-poppins text-l">Fusorlabs</h1>
          <h1 class="font-poppins text-l">ABN: 19 137 489 585</h1>
          <h1 class="font-poppins text-l">Master License No. | NSW 000 108 254</h1>       
        </div>        
      </div>
      
    </div>
    
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import { ref, onMounted} from 'vue';
export default {
  components: { 
    NavBar 
  },
  setup() {
    const reviewDemoVideoRef = ref(null);
    const videoBannerRef = ref(null);
    const reportUploadVideoRef = ref(null);

    onMounted(() => {
      playVideo(reviewDemoVideoRef.value);
      playVideo(videoBannerRef.value);
      playVideo(reportUploadVideoRef.value);
    });

    const playVideo = (video) => {
      // Try to play the video
      const promise = video.play();

      // If video doesn't support play() or if it fails
      if (promise !== undefined) {
        promise.catch(error => {
          // Autoplay was prevented.
          console.log(`Can't autoplay: ${error}`)
        }).then(() => {
          // Video started playing successfully
        });
      }
    }
    return {
      reviewDemoVideoRef,
      videoBannerRef,
      reportUploadVideoRef
    }
  }
};
</script>