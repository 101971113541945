<template>
  <!-- Raw vid size: 3072 x 1728 -->
  <div v-if="vidUrl !== undefined">
    <video 
    :src="`${vidUrl}#t=2.5`" 
    :width="width" 
    :height="height"
    :playbackRate=speed
    preload="metadata"
    controls
    muted
    ></video>      
  </div>
  <div v-else>
    <h1>Problem loading video</h1>
  </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { useStateStore } from '@/stores/StateStore';
import { createSignedUrl } from '@/S3Bucket';
import { getFirebaseUrl } from '@/firebase';

export default {
  props: ['vidPath', 'metadata', 'width', 'height'],
  setup(props) {
    const stateStore = useStateStore();
    const speed = ref("1");

    // Watch for changes in the "playbackSpeed" state
    watch(() => stateStore.playbackSpeed, (newPlaybackSpeed) => {
      speed.value = newPlaybackSpeed;
    });

    const vidUrl = ref();

    const getVideoUrl = async () => {
      let url = null;

      try {
        // New Standard
        url = await createSignedUrl(props.metadata.b2_bucket, props.vidPath);
        if (url == null) {
          throw "Error creating signed url"
        }
        console.log("new storage")
      } catch {
        // Old standard
        const firebaseUrl = await getFirebaseUrl(props.vidPath);
        url = firebaseUrl;
        console.warn("old storage")
      }

      if (url == null) {
        throw new Error('Error getting video download URL')
      }

      return url
    };

    onMounted(async () => {
      vidUrl.value = await getVideoUrl();    
    });


    return {
      speed,
      vidUrl,
    }
  }
};
</script>