<template>
    <div :key="rerenderTrigger">
        <div class="p-1 flex flex-row items-center justify-center">
            <button @click="decrementDate" class="m-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" class="bi bi-arrow-left-short fill-white hover:fill-teal-200" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                </svg>               
            </button>
 
            <div class="hover:border-teal-200 hover:border-2 rounded-md">
                <Datepicker
                    v-model="selectedDate"
                    :enable-time-picker="false"
                    :clearable="false"
                    :format="formatDate"
                    :min-date="minDate"
                    :max-date="maxDate"
                    auto-apply
                />
            </div>

            <button @click="incrementDate" class="m-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" class="bi bi-arrow-right-short fill-white hover:fill-teal-200" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                </svg>                  
            </button>
        </div>



        <div class="mb-5">
            <!-- <h1 class="font-semibold text-2xl">{{ dateFormat(selectedDate) }}</h1> -->
            <div class="flex items-center justify-center">
                <pdf2-csv :date="selectedDate"></pdf2-csv>
            </div>

            <TailgatingStatsMessage :date="selectedDate" :tailgaters="videoCount"></TailgatingStatsMessage>
            <VideoGrid 
            :date=selectedDate
            :width="3072/5" 
            :height="1728/5"
            :getVideoCount="getVideoCount"/>
        </div>
    </div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { ref, watch } from 'vue';
import VideoGrid from './VideoGrid.vue';
import Pdf2Csv from '../Pdf2Csv.vue';
import TailgatingStatsMessage from '../TailgatingStatsMessage.vue';
import { useStateStore } from '@/stores/StateStore';

export default {
    setup() {
        const minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
        const maxDate = new Date();
        const selectedDate = ref(new Date());

        const stateStore = useStateStore();
        const rerenderTrigger = ref(0);
        watch(() => stateStore.selectedLocation, () => {
            rerenderTrigger.value++;

            // Reset number of dates being displayed to ONLY 1 day (i.e. today) when switching to another location
        });
        watch(() => selectedDate.value, () => {
            rerenderTrigger.value++;
        });

        const incrementDate = () => {
            selectedDate.value.setDate(selectedDate.value.getDate() + 1);
            rerenderTrigger.value++;
        }
        const decrementDate = () => {
            selectedDate.value.setDate(selectedDate.value.getDate() - 1);
            rerenderTrigger.value++;
        }
        // const formatDate = (date) => {
        //     const day   = date.getDate();
        //     const month = date.getMonth() + 1;
        //     const year  = date.getFullYear();
        //     return `${day}/${month}/${year}`;
        // }

        const formatDate = (date) => {
            const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            
            const dayOfWeek = days[date.getDay()];
            const dayOfMonth = date.getDate();
            const month = months[date.getMonth()];
            const year = date.getFullYear();

            return `${dayOfWeek} ${dayOfMonth} ${month} ${year}`;
        }

        const videoCount = ref(0);
        const getVideoCount = (count) => {
            videoCount.value = count;
        }

        return {
            minDate,
            maxDate,
            rerenderTrigger,
            selectedDate,
            formatDate,
            incrementDate,
            decrementDate,
            videoCount,
            getVideoCount,
        };
    },
    components: { VideoGrid, Pdf2Csv, Datepicker, TailgatingStatsMessage }
};
</script>