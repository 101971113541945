<template>
  <default-layout>
    <CardShareFeed></CardShareFeed>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout";
import CardShareFeed from '@/components/cardShare/CardShareFeed.vue';

export default {
  components: {
    DefaultLayout,
    CardShareFeed,
},

  setup() {
 
    return {

    }
  }
};
</script>