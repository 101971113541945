<template>
  <div>
    <NavBar class="z-[99]"/>

    <div class="container mt-3 px-2">
      <div class="bg-slate-900 flex flex-col items-center">
        <h1 class="pt-10 font-bold text-2xl">Your Subscription has expired</h1>       
        <h1 class="pt-5">If you wish to resuscribe, please email us at support@fusorlabs.com</h1>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
export default {
  components: {
    NavBar,
},

  setup() {
 
    return {

    }
  }
};
</script>