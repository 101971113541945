<template>
  <div class="row">
    <div class="col">
      
      <h2>Pricing</h2>
      
      <hr />

      <div v-for="(product, index) in products" 
            class="flex justify-center py-5"
            :key="index + '-product'">

        <div
          v-for="(price, priceIndex) in product.prices"
          :key="priceIndex + '-price'"
          class="p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md flex flex-col items-center space-x-4">

          <!-- <div class="form-check">
            <input
              type="radio"
              class="form-check-input"
              v-model="selectedPrice"
              :value="price.id"
              :id="price.id" />
            
            <label :for="price.id" class="form-check-label">
              {{ product.name }}: ${{ price.unit_amount/100 }}/{{ price.interval }}
            </label>
          </div> -->

          <div>
            <h2 class="text-xl text-gray-500 font-semibold">{{ product.name }}</h2>
            <p class="text-gray-500">${{ price.unit_amount/100 }}/{{ price.interval }}</p>
            
            <button
              v-if="!isLoading"
              class="form-check-input text-black bg-transparent hover:text-teal-200 font-semibold py-2 px-4 border border-blue-500 hover:border-teal-200 rounded"
              @click="testFunc(price.id)">
              Subscribe
            </button>

            <div v-if="isLoading" type="button" class="bg-transparent font-semibold py-2 px-4 flex flex-row justify-center w-fit" disabled>
                <svg class="animate-spin h-5 w-5 mr-3 ..." fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg" >
                    <path d="M10 3C6.13401 3 3 6.13401 3 10C3 10.2761 2.77614 10.5 2.5 10.5C2.22386 10.5 2 10.2761 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C9.72386 18 9.5 17.7761 9.5 17.5C9.5 17.2239 9.72386 17 10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3Z" fill="#000000"/>
                </svg>
                <span class="text-black">Taking you to checkout...</span>
            </div>

            <ul class="list-disc list-inside">
              <li v-for="(feature, featureIndex) in product.metadata" :key="featureIndex" class="text-gray-500">
                {{ feature }}
              </li>
            </ul>
          </div>

        </div>
      </div>

      <!-- <button
        class="btn-primary"
        @click="createSub"
        :disabled="!selectedPrice || isLoading"
      >
        {{ isLoading ? "Loading..." : "Create subscription" }}
      </button> -->
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import {
  getFirestore,
  getDocs,
  where,
  query,
  collection,
  addDoc,
  onSnapshot,
  limit,
} from "firebase/firestore";
import { firebaseAuth } from "@/firebase";

export default {
  setup() {
    const products = ref([]);
    const selectedPrice = ref(null);
    const isLoading = ref(false);

    const fetchProducts = async () => {
      const db = getFirestore();
      const productsRef = collection(db, "products");

      const productsQuery = query(productsRef, where("active", "==", true), limit(100));
      // !!! ATTENTION hardcode to display only "Tailgating Detection"
      // const productsQuery = query(productsRef, where("active", "==", true), where("name", "==", "Tailgating Detection"), limit(100));
      
      const productsQuerySnap = await getDocs(productsQuery);
      productsQuerySnap.forEach(async (doc) => {
        const pricesRef = collection(db, "products", doc.id, "prices");
        const pricesQuery = query(pricesRef, where("active", "==", true), limit(100));
        const pricesQuerySnap = await getDocs(pricesQuery);
        products.value.push({
          id: doc.id,
          ...doc.data(),
          prices: pricesQuerySnap.docs.map((price) => {
            return {
              id: price.id,
              ...price.data(),
            };
          }),
        });
      });
    };

    onMounted(() => {
      fetchProducts();
    });

    const createSub = async () => {
      isLoading.value = true;
      const db = getFirestore();
      const collectionRef = collection(
        db,
        "customers",
        firebaseAuth.currentUser.uid,
        "checkout_sessions"
      );
      const docRef = await addDoc(collectionRef, {
        price: selectedPrice.value,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });
      onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data();
        if (error) {
          console.error(`An error occured: ${error.message}`);
          isLoading.value = false;
        }
        if (url) {
          window.location.assign(url);
        }
      });
    };

    const testFunc = async (priceId) => {
      isLoading.value = true;
      const db = getFirestore();
      const collectionRef = collection(
        db,
        "customers",
        firebaseAuth.currentUser.uid,
        "checkout_sessions"
      );
      const docRef = await addDoc(collectionRef, {
        price: priceId,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });
      onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data();
        if (error) {
          console.error(`An error occured: ${error.message}`);
          isLoading.value = false;
        }
        if (url) {
          window.location.assign(url);
        }
      });
    }
    return {
      products,
      selectedPrice,
      isLoading,
      fetchProducts,
      createSub,
      testFunc
    }
  },
};
</script>