import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AccountView from '../views/AccountView.vue'
import SignInView from '../views/SignInView.vue'
import RegisterView from '../views/RegisterView.vue'
import InboxView from '../views/InboxView.vue'
import CardShareView from '@/views/CardShareView.vue'
import ConfirmedDetectsView from '../views/ConfirmedDetectsView.vue'
import AdminHomeView from '../views/AdminHomeView.vue'
import CreateAccountView from '../views/CreateAccountView.vue'
import SubscriptionExpiredView from '../views/SubscriptionExpiredView.vue'

import { useStateStore } from '@/stores/StateStore';
import { getCurrentUser, checkAdmin, getUserCustomClaims} from '@/firebase'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/account',
    name: 'Account',
    component: AccountView,
    meta: {
      requiresAuth: true,
      requiresOwner: true,
    }
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: SignInView,
    meta: {
      guestOnly: true,
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView,
    meta: {
      guestOnly: true,
    }
  },
  {
    path: '/inbox',
    name: 'Inbox',
    component: InboxView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/multi_entry',
    name: 'CardShare',
    component: CardShareView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/confirmed_detections',
    name: 'Confirmed',
    component: ConfirmedDetectsView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/admin_home',
    name: 'AdminHome',
    component: AdminHomeView,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/create_account',
    name: 'CreateAccount',
    component: CreateAccountView,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/subscription_expired',
    name: 'SubscriptionExpired',
    component: SubscriptionExpiredView,
    meta: {
      requiresAuth: true,
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const stateStore = useStateStore()

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin)
  const requiresOwner = to.matched.some(record => record.meta.requiresOwner)
  // const isGuestOnly = to.matched.some(record => record.meta.guestOnly)

  const currentUser = await getCurrentUser()
  const isAdmin = await checkAdmin()

  const currLocationId = stateStore.userLocations[stateStore.selectedLocation]
  const customClaims = await getUserCustomClaims();
  let isOwner = false;
  if (customClaims) {
    if (customClaims.location_permissions) {
      const locationPermission = customClaims.location_permissions[currLocationId]
      isOwner = locationPermission == 'owner'
    }
  }

  if (requiresAuth && !currentUser) {
    next('/signin')
  } 
  else if (requiresAdmin && !isAdmin){
    next('/inbox')
  }
  else if (requiresOwner && !isOwner) {
    next('/')
  }
  else {
    next()
  }
})

export default router
