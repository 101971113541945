<template>
  <div>
    <NavBar class="z-[99]"/>

    <div class="container mt-3 px-2">
      <video-feed></video-feed>
    </div>
  </div>
</template>

<script>
import VideoFeed from "@/components/inbox/VideoFeed";
import NavBar from "@/components/NavBar";
export default {
  components: {
    VideoFeed,
    NavBar,
},

  setup() {
 
    return {

    }
  }
};
</script>