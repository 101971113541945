<template>
  <div class="bg-theme-tertiary">
    <NavBar/>
    <h1>Registration Page</h1>
    <br />
    <p>A sign in pop up will appear after the correct registration code is submitted</p>
    <input
      class="text-black"
      type="text"
      placeholder="Enter your registration code"
      v-model="code"
    />
    <button 
      class="btn-primary"
      @click="handleClick">
      Submit
    </button>
    <br />
    <b class="text-red-500">{{ message }}</b>
    <br />
    <p>Don't have a registration code? Contact us for pricing and solutions for your business</p>

    <h1>Email Sign Up</h1>
    <form @submit.prevent="emailSignUp">
      <label>
        Email:
        <input class="text-black" type="email" v-model="email" required>
      </label>
      <label>
        Password:
        <input class="text-black" type="password" v-model="password" required>
      </label>
      <button class="btn-primary" type="button" @click="emailSignUp">Sign Up</button>
    </form>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import { ref } from 'vue';
import {getAuth, GoogleAuthProvider, signInWithPopup, createUserWithEmailAndPassword} from 'firebase/auth'
import {
  // collection,
  // getFirestore,
  getDocs,
  where,
  query,
  limit,
} from "firebase/firestore";
import { getLocationsCollectionRef } from "@/firebase";
import { useRouter } from 'vue-router'

export default {
  components: { 
    NavBar 
  },
  setup() {
    const router = useRouter();
    const allowSignIn = ref(false);
    const message = ref('');
    const code = ref('');
    let submittedCode = '';

    const handleClick = async() => {
      if (code.value !== ""){
        submittedCode = code.value;
        // const db = getFirestore();
        // const locationsRef = collection(db, "locations");
        const locationsRef = getLocationsCollectionRef();
        const locationsQuery = query(locationsRef, where("linkcode", "==", submittedCode), limit(1));
        const locationsQuerySnap = await getDocs(locationsQuery);
        try {
          if (locationsQuerySnap.size === 0) {
            console.log('Invalid Registration code');
            message.value = 'Invalid Registration code';
            allowSignIn.value = false;
          } 
          
          else {
            console.log('Registration code is valid');
            message.value = 'Sucess! Opening sign in popup...';
            allowSignIn.value = true;
            signIn();
          }
        }
        catch (error) {
          console.log(error);
        }        
      }
      
      else {
        message.value = 'Please enter a Registration code';
        allowSignIn.value = false;
      }

    }

    const signIn = async () => {
      const provider = new GoogleAuthProvider()
      const auth = getAuth();

      return await signInWithPopup(auth, provider)
        .then(() => router.replace({ name: 'Inbox' }))
    }

    const email = ref('');
    const password = ref('');
    const emailSignUp = async() => {
      const auth = getAuth();
      return await createUserWithEmailAndPassword(auth, email.value, password.value)
        .then(() => router.replace({ name: 'Inbox' }))
        .catch((error) => {
          // const errorCode = error.code;
          // const errorMessage = error.message;
          console.log(error);
        });      
    }

    return {
      code,
      message,
      handleClick,
      allowSignIn,
      email,
      password,
      emailSignUp,
    };
  },
};
</script>