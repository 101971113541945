<template>
  <div>
    <slot name="openButton" :onButtonClick="onOpen">
      <button @click="onOpen">
        {{ openButtonName }}
      </button>      
    </slot>

    <div v-if="showModal" class="modal z-[100]">
      <div class="modal-content">
        <div class="modal-header flex flex-row">
          <slot name="header"></slot>
          <button @click="closeModal" class="hover:bg-red-500 mb-2">
            <svg height="30" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg">
              <path d="M21 8.84L19.16 7 15 11.16 10.84 7 9 8.84 13.16 13 9 17.16 10.84 19 15 14.84 19.16 19 21 17.16 16.84 13 21 8.84z" fill="#ffffff"/>
              <path d="M0 0h30v30H0z" fill="none"/>
            </svg>
          </button>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onBeforeUnmount } from 'vue';

export default {
  props: ["openButtonName", "onModalOpen", "onModalClose"],
  setup(props) {
    const showModal = ref(false);
    const onOpen = () => {
      if(props.onModalOpen){
        props.onModalOpen();
      }
      
      showModal.value = true;
      document.body.style.overflow = 'hidden';
    }
    
    const closeModal = () => {
      showModal.value = false;
      document.body.style.overflow = '';
      if(props.onModalClose){
        props.onModalClose();
      }
    };

    onBeforeUnmount(() => {
      document.body.style.overflow = '';
    })
    return {
      onOpen,
      closeModal,
      showModal
    };
  }
};
</script>

<style>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0; /* added */
  bottom: 0; /* added */
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #000000;
  padding: 20px;
  border-radius: 4px;
  max-width: 80%;
  max-height: 80%;
  display: flex;
  flex-direction: column;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-body {
  overflow-y: auto;
  flex-grow: 1;
  overscroll-behavior: none;
}
</style>