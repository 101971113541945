<template>
  <div :key="rerenderTrigger" class="flex flex-col items-center space-y-3">
    <!-- <div class="border-2 border-dashed border-gray-300 p-4 rounded-lg">
        <h2 class="underline font-semibold flex justify-center mb-3">Upload Door Report</h2>
        <input id="fileInput" type="file" @change="onFileSelected">
    </div> -->

    <div v-if="!processingPdf && !uploadingReport" class="p-2">
        <input type="file" id="fileInput" class="hidden" @change="onFileSelected">
        <label for="fileInput" class="file-upload-label cursor-pointer bg-transparent hover:text-teal-200 text-white font-semibold py-2 px-4 border border-white hover:border-teal-200 rounded">Upload Door Report</label>
    </div>


    <!-- ####### New Streamlined Process ####### -->
    <div v-if="processingPdf" type="button" class="bg-transparent font-semibold py-2 px-4 border border-cyan-200 rounded flex flex-row justify-center w-fit" disabled>
        <svg class="animate-spin h-5 w-5 mr-3 ..." fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg" >
            <path d="M10 3C6.13401 3 3 6.13401 3 10C3 10.2761 2.77614 10.5 2.5 10.5C2.22386 10.5 2 10.2761 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C9.72386 18 9.5 17.7761 9.5 17.5C9.5 17.2239 9.72386 17 10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3Z" fill="#18ffff"/>
        </svg>
        <span class="text-cyan-200">Processing...</span>
    </div>

    <div v-if="!uploadReportComplete && uploadingReport" type="button" class="bg-transparent font-semibold py-2 px-4 border border-cyan-200 rounded flex flex-row justify-center w-fit" disabled>
        <svg class="animate-spin h-5 w-5 mr-3 ..." fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg" >
            <path d="M10 3C6.13401 3 3 6.13401 3 10C3 10.2761 2.77614 10.5 2.5 10.5C2.22386 10.5 2 10.2761 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C9.72386 18 9.5 17.7761 9.5 17.5C9.5 17.2239 9.72386 17 10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3Z" fill="#18ffff"/>
        </svg>
        <span class="text-cyan-200">Adding Report...</span>
    </div>

    <div v-if="uploadReportComplete && !uploadingReport" class="bg-transparent text-white font-semibold pb-5">Report Added!</div>
    <!-- ####### New Streamlined Process ####### -->
    <button v-if="isAdmin" @click="onRemoveReport">Remove Report</button>
    <button v-if="isAdmin" @click="onRematchReport">Rematch Report</button>

    <ModalComponent v-if="csvData && isAdmin" openButtonName="View Report" :onModalOpen="displayCSV">
        <template v-slot:openButton="{ onButtonClick }">
            <button @click="onButtonClick"
                    class="bg-transparent hover:text-teal-200 text-white font-semibold py-1 border border-white hover:border-teal-200 rounded">
                View Report
            </button>
        </template>

        <template v-slot:header>
            <button v-if="!uploadReportComplete && !uploadingReport" @click="processReport" class="bg-transparent hover:text-teal-200 text-white font-semibold py-2 px-4 border border-white hover:border-teal-200 rounded">Add report</button>
            <div v-if="!uploadReportComplete && uploadingReport" type="button" class="bg-transparent font-semibold py-2 px-4 border border-cyan-200 rounded flex flex-row justify-center w-fit" disabled>
                <svg class="animate-spin h-5 w-5 mr-3 ..." fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg" >
                    <path d="M10 3C6.13401 3 3 6.13401 3 10C3 10.2761 2.77614 10.5 2.5 10.5C2.22386 10.5 2 10.2761 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C9.72386 18 9.5 17.7761 9.5 17.5C9.5 17.2239 9.72386 17 10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3Z" fill="#18ffff"/>
                </svg>
                <span class="text-cyan-200">Adding Report...</span>
            </div>
            <button v-if="uploadReportComplete && !uploadingReport" class="bg-transparent hover:text-teal-200 text-white font-semibold py-2 px-4 border border-white hover:border-teal-200 rounded">Report Added!</button>
        </template>

        <template v-slot:body>
            <table v-if="htmlCsv">
                <tr v-for="(row, rowIndex) in htmlCsv" :key="rowIndex">
                <td v-for="(cell, cellIndex) in row" :key="cellIndex">{{ cell }}</td>
                </tr>
            </table>  
        </template>
    </ModalComponent>

    <!-- <button v-if="csvData" @click="downloadCsv" class="bg-transparent hover:text-teal-200 text-white font-semibold py-2 px-4 border border-white hover:border-teal-200 rounded">Download CSV</button> -->
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { processDoorReport, removeOldReports, matchReportToVideoV1 } from '@/components/PdfProcessing';
import { useStateStore } from '@/stores/StateStore';
import ModalComponent from './ModalComponent.vue';
import { checkAdmin } from '@/firebase'

export default {
    name: "PdfToCsvConverter",
    props: ["date", "callback"],
    setup(props) {
        const processingPdf = ref(false);
        const uploadingReport = ref(false);
        const uploadReportComplete = ref(false);

        const htmlCsv = ref(null);
        const stateStore = useStateStore();
        const selectedLocationId = ref();
        selectedLocationId.value = stateStore.userLocations[stateStore.selectedLocation];
        const csvData = ref(null);
        const processedReport = ref(null);
        const duplicateSuspectsInfo = ref(null);
        const displayCSV = () => {
            // Decode the Base64 string to convert it back to CSV
            const decodedCSV = csvData.value;
            // Parse the CSV data
            const parsedData = [];
            const rows = decodedCSV.split("\n");
            for (let i = 0; i < rows.length; i++) {
                const cells = rows[i].split(",");
                parsedData.push(cells);
            }
            // Set the parsed CSV data to the csvData ref
            htmlCsv.value = parsedData;
        };

        const onFileSelected = async (event) => {
            // Reset Flags
            uploadingReport.value = false;
            uploadReportComplete.value = false;

            processingPdf.value = true;
            const pdfFile = event.target.files[0];
            const formData = new FormData();
            formData.append("file", pdfFile);
            try {
                const doorReportUrl = process.env.VUE_APP_BACKEND_URL;
                await fetch(doorReportUrl, {
                    method: "POST",
                    body: formData,
                })
                    .then(response => response.text())
                    .then(data => {
                    let json_data = JSON.parse(data);
                    
                    csvData.value = atob(json_data.csv_attachment);
                    processedReport.value = json_data.report;
                    duplicateSuspectsInfo.value = json_data.suspects_info;
                })
                    .catch(error => {
                    console.error(error);
                });

                processingPdf.value = false;
                await processReport(); // New Streamlined Process
            }
            catch (error) {
                console.error(error);
            }
        };
        const downloadCsv = () => {
            const csvBlob = new Blob([csvData.value], { type: "text/csv" });
            const csvUrl = window.URL.createObjectURL(csvBlob);
            const link = document.createElement("a");
            link.href = csvUrl;
            link.download = "converted.csv";
            link.click();
        };

        const onRemoveReport = async () => {
            await removeOldReports(props.date, selectedLocationId.value)
            alert("Reports removed")
        }

        const onRematchReport = async () => {
            await matchReportToVideoV1(props.date, selectedLocationId.value)
            alert("Reports rematched")
        }

        const rerenderTrigger = ref(0);
        const processReport = async () => {
            uploadingReport.value = true;

            try {
                const success = await processDoorReport(processedReport.value, props.date, selectedLocationId.value, duplicateSuspectsInfo.value);
                if (success) {
                    uploadingReport.value = false;
                    uploadReportComplete.value = true;
                    if (props.callback) {
                        props.callback();
                    }                    
                }

                else {
                    uploadingReport.value = false;
                    uploadReportComplete.value = false;
                    rerenderTrigger.value ++;
                }                
            } catch (error) {
                uploadingReport.value = false;
                uploadReportComplete.value = false;
                rerenderTrigger.value ++;
                throw new Error(error);
            }

        }

        const isAdmin = ref(false);
        onMounted( async () =>{
            isAdmin.value = await checkAdmin();
        })
        return {
            isAdmin,
            csvData,
            processingPdf,
            onFileSelected,
            downloadCsv,
            htmlCsv,
            displayCSV,
            processReport,
            uploadingReport,
            uploadReportComplete,
            rerenderTrigger,
            onRemoveReport,
            onRematchReport,
        };
    },
    components: { ModalComponent }
};
</script>