<template>
  <div class="bg-theme-tertiary flex flex-col">
    <div class="max-w-xs">

      <p class="font-semibold text-xl underline text-gray-400 mt-3">Location Details</p>
      <div class="flex">
        <div class="space-y-4">
          <!-- email, name, phoneCountry, rawPhoneNumber, e164PhoneNumber, formatPhoneNumber -->
          <div class="flex flex-col">
            <label for="franchise" class="mb-2 text-sm font-medium">Franchise (e.g. Plus Fitness):</label>
            <input v-model="data.franchise" type="text" id="franchise" required class="p-2 border border-gray-300 rounded text-black" />
          </div>

          <p class="font-semibold text-m underline text-gray-400">Address</p>
          <div class="ml-5">
            <div class="flex flex-col">
              <label for="line1" class="mb-2 text-sm font-medium">Line1:</label>
              <input v-model="data.address.line1" type="text" id="address line 1" required class="p-2 border border-gray-300 rounded text-black" placeholder="Line1"/>
            </div>
            <div class="flex flex-col">
              <label for="city" class="mb-2 text-sm font-medium">City:</label>
              <input v-model="data.address.city" type="text" id="city" required class="p-2 border border-gray-300 rounded text-black" placeholder="City"/>
            </div>
            <div class="flex flex-col">
              <label for="region" class="mb-2 text-sm font-medium">State:</label>
              <input v-model="data.address.region" type="text" id="state" required class="p-2 border border-gray-300 rounded text-black" placeholder="Region"/>
            </div>
            <div class="flex flex-col">
              <label for="postal-code" class="mb-2 text-sm font-medium">Postal Code:</label>
              <input v-model="data.address.postalCode" type="text" id="postal-code" required class="p-2 border border-gray-300 rounded text-black" placeholder="Postal Code"/>
            </div>
            <div class="flex flex-col">
              <label for="country" class="mb-2 text-sm font-medium">Country:</label>
              <input v-model="data.address.country" type="text" name="country" id="country" required class="p-2 border border-gray-300 rounded text-black" placeholder="Country"/>
            </div>            
          </div>
          
          <p class="font-semibold text-m underline text-gray-400">Report Settings</p>
          <div class="ml-5">
            <div class="flex flex-col m-2">
              <label for="rangeexpansion" class="mb-2 text-sm font-medium">Range Expansion Seconds:</label>
              <p class="pl-5 text-sm">If video runs from 15:09:12-15:09:23, a 10 second expansion will search 15:09:02-15:09:33</p>
              <input v-model="data.reportSettings.rangeExpansion" type="text" id="rangeexpansion" required class="p-2 border border-gray-300 rounded text-black" placeholder="0"/>
            </div>
            <div class="flex flex-col m-2">
              <label for="timeoffset" class="mb-2 text-sm font-medium">Time Offset Seconds:</label>
              <p class="pl-5 text-sm">If video runs from 15:09:12-15:09:23, an offset of -2 will search from 15:09:10-15:09:21</p>
              <input v-model="data.reportSettings.timeOffset" type="text" id="timeoffset" required class="p-2 border border-gray-300 rounded text-black" placeholder="0"/>
            </div>           
          </div>

          <p class="font-semibold text-m underline text-gray-400">Camera(s)</p>
          <li v-for="(id, name) in data.camera.door_names" :key="name">
            <h2 class="font-semibold text-m">Name: {{name}}</h2>
            <h2 class="font-semibold text-xs">{{data.camera.areas[id]}}</h2>
          </li>
            
          <ModalComponent openButtonName="Open">
            <template v-slot:openButton="{ onButtonClick }">
              <button @click="onButtonClick"
                      v-on:click="modalOpened"
                      class="bg-transparent hover:text-teal-200 text-blue-700 font-semibold py-2 px-4 ml-16 border border-blue-500 hover:border-teal-200 rounded">
                Add Zones
              </button>
            </template>
    
            <template v-slot:body>
              <div class="mb-3 flex flex-col">
                <label for="camera-name" class="mb-2 text-sm font-medium">Door (must match door report):</label>
                <input v-model="data.cameraName" type="text" id="camera-name" required class="p-2 border border-gray-300 rounded text-black w-fit" placeholder="Camera name"/>
              </div>
                <DrawPolygon :handlePolygonData="savePolygon"></DrawPolygon>
            </template>
          </ModalComponent>

          <button @click="handleSubmit" class="p-2 mt-4 bg-blue-500 text-white rounded hover:bg-blue-700">Create Location</button>
          
        </div>
      </div>
      
    </div>
  </div>
</template>

<script setup>
import DrawPolygon from '@/components/DrawPolygon.vue';
import ModalComponent from '@/components/ModalComponent.vue';

import { createNewLocation } from "@/firebase"
import { ref, watch, defineEmits } from 'vue';

const data = ref({
  franchise: 'Plus Fitness',
  address: {
    line1: 'test',
    city: 'test',
    region: 'NSW',
    postalCode: '2000',
    country: "AU"
  },
  reportSettings: {
    rangeExpansion: 0,
    timeOffset: 0,
  },
  cameraName: 'Front',
  camera: {
    areas: {},
    door_names: {}
  }
});

const savePolygon = (polygonData) => {
  if (!data.value.cameraName){
    alert("Add camera name before adding zone")
  }
  else {
    const usedCamIds = Object.keys(data.value.camera.areas);
    const camId = uniqueId(usedCamIds);
  
    data.value.camera.door_names[data.value.cameraName] = camId;
    data.value.camera.areas[camId] = polygonData;
    console.log("save polygon")
    console.log(data.value.camera);
  }
}

const uniqueId = (usedIds) => {
  let uid = generateId();
  let count = 0;
  while (usedIds.includes(uid)){
    if(count > 1000) {
      throw new Error('Camera id generation stuck in infinite loop. Breaking...')
    }
    uid = generateId();
    count++;
  }
  return uid
}

const generateId = () => {
  return Date.now().toString(36) + Math.random().toString(36).substring(2, 11);
}

const emit = defineEmits(['update:modelValue', 'submitted']);

// Watch for changes in inputValue and emit an update event
watch(data, (newValue) => {
  emit('update:modelValue', newValue);
});

// Emit the current value when the form is submitted
const handleSubmit = async () => {
  const locationId = await createLocation();
  emit('submitted', locationId);
}

const createLocation = async() => {  
  // Create Location Doc
  const locationName = `${data.value.address.city} ${data.value.franchise}`
  
  const locationId = await createNewLocation( locationName,
                                              data.value.franchise,
                                              data.value.camera.areas,
                                              data.value.camera.door_names,
                                              data.value.address,
                                              data.value.reportSettings,
                                              );

  console.log("Successfully created location!")
  return locationId
}

</script>