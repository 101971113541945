<template>
  <div class="slider flex items-center space-x-4">
    <h1>Speed</h1>
    <input
      type="range"
      min="1"
      max="5"
      v-model="selectedValue"
      @input="handleSliderChange"
      class="w-36"
    />
    <span class="text-xl font-bold">{{ selectedValue }}</span>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useStateStore } from '@/stores/StateStore';

export default {
  setup() {
    const stateStore = useStateStore();
    const selectedValue = ref("1");

    const handleSliderChange = (event) => {
      selectedValue.value = event.target.value;
      stateStore.playbackSpeed = selectedValue.value;
    };

    onMounted(() => {
      selectedValue.value = stateStore.playbackSpeed;
    })

    return {
      selectedValue,
      handleSliderChange,
    };
  },
};
</script>