<template>
  <div class="bg-theme-teriary">
    <NavBar class="z-[99]"/>

    <div class="container mt-3 overflow-hidden">
      <slot />
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
export default {
  components: { NavBar },
};
</script>