<template>
  <default-layout>
    <h1 class="font-bold pb-2">Sign In with email</h1>
    <form @submit.prevent="emailSignIn">
      <label>
        Email:
        <input class="text-black" type="email" v-model="email" required>
      </label>
      <label>
        Password:
        <input class="text-black" type="password" v-model="password" required>
      </label>
      <button class="btn-primary ml-5" type="button" @click="emailSignIn">Sign In</button>
    </form>

    <div class="mt-10">
      <h1 class="font-bold pb-2">Or with Google</h1>
      <button class="btn-primary" @click="googleSignIn">Google Sign In</button>
    </div>

    <h1>Don't have an account? Contact us to sign up</h1>
  </default-layout>
</template>

<script>
import {getAuth, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword} from 'firebase/auth'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import { useRouter } from 'vue-router'
import { ref } from 'vue';


export default {
  setup() {
    const router = useRouter()

    const googleSignIn = async () => {
      const provider = new GoogleAuthProvider()
      const auth = getAuth();

      return await signInWithPopup(auth, provider)
      .then( (result) => {
                          // This gives you a Google Access Token. You can use it to access the Google API.
                          GoogleAuthProvider.credentialFromResult(result);
                          // const credential = GoogleAuthProvider.credentialFromResult(result);
                          // const token = credential.accessToken;

                          // Use the token to search for a file in Google Drive
                          //const query = encodeURI('mimeType="application/vnd.google-apps.document" and trashed = false and name contains "example"');
                          // const url = `https://www.googleapis.com/drive/v3/files/1Vr-Oxy9AF410ZrPe0Ms7x_Uft0j9DwEU`;

                          // fetch(url, {
                          //   method: "GET",
                          //   headers: {
                          //     "Authorization": "Bearer " + token,
                          //   }
                          // })
                          // .then(response => response.json())
                          // .then(data => {
                          //   console.log(data);
                          //   // data.files will contain an array of all the files matching the query
                          // })
                          // .catch(error => console.error(error));
                        })
        .then(() => router.replace({ name: 'Inbox' }))
    }

    const email = ref('');
    const password = ref('');
    const emailSignIn = async() => {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, email.value, password.value)
        .then(() => {
          // Signed in 
          // const user = userCredential.user;
          router.replace({ name: 'Inbox' })
        })
        .catch((error) => {
          // const errorCode = error.code;
          // const errorMessage = error.message;
          console.log(error);
        });      
    }


    return {
      googleSignIn,
      email,
      password,
      emailSignIn,
    }
  },
  components: { DefaultLayout },
}
</script>