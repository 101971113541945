<template>
  <div class="relative">
    <select v-model="selectedOption"
            @change="onChange"
            class="block appearance-none w-full text-black bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
      <option disabled value="">Select a location</option>
      <option v-for="(item, index) in Object.keys(userLocations)"
          :value="item"
          :key="index"
      >
          {{ item }}
      </option>
    </select>
    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
      <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M14.707 7.293a1 1 0 0 0-1.414 0L10 10.586 6.707 7.293a1 1 0 1 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l3-3a1 1 0 0 0 0-1.414z"/></svg>
    </div>
  </div>
</template>

<script>
import { onMounted, 
        ref,
} from 'vue';
import {  checkAdmin,
          getClientLocations,
          getLocations,
} from "@/firebase";
import { useStateStore } from '@/stores/StateStore';
export default {
  props: ['handleSelect'],
  setup(props) {
    const userLocations = ref({});
    const selectedOption = ref();
    const stateStore = useStateStore();

    const onChange = (event) => {
      stateStore.selectedLocation = event.target.value;
      selectedOption.value = event.target.value;
      if (props.handleSelect) {
        props.handleSelect(selectedOption.value)
      }
    }

    onMounted(async() => {
      // Initialising locations
      if (Object.keys(stateStore.userLocations).length == 0) {
        const isAdmin = await checkAdmin();
        if (isAdmin){
          userLocations.value = await getLocations();
        }
        else {
          userLocations.value = await getClientLocations();
        }
        
        stateStore.setUserLocations(userLocations.value);
      }
      else {
        // State store already populated
        userLocations.value = stateStore.userLocations;
      }

      // Selected Location logic
      if (stateStore.selectedLocation) {
        selectedOption.value = stateStore.selectedLocation;
      }
      else {
        stateStore.selectedLocation = Object.keys(stateStore.userLocations)[0];
        selectedOption.value = stateStore.selectedLocation;
      }
      
      // Callback function
      if (props.handleSelect) {
        props.handleSelect(selectedOption.value)
      }
    })

    return {
      userLocations,
      onChange,
      selectedOption,
    };
  },
};
</script>