import { getApps, initializeApp } from "firebase/app"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { getStorage, ref as ref_storage, getDownloadURL, } from "firebase/storage";
import {
  getFirestore,
  collection,
  where,
  query,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  Timestamp,
  limit,
  orderBy,
  or,
  getCountFromServer,
  setDoc,
  arrayUnion,
  deleteField,
} from "firebase/firestore";
import { getFunctions } from 'firebase/functions';
import { getMessaging, onMessage } from "firebase/messaging";

// DEVELOPMENT
const firebaseConfigDevelopment = {
  apiKey: "AIzaSyA8d5YsPdeFIn9wD0ABoyRLdtc4R2e4HbE",
  authDomain: "tailgating-detection.firebaseapp.com",
  projectId: "tailgating-detection",
  storageBucket: "tailgating-detection.appspot.com",
  messagingSenderId: "686948867200",
  appId: "1:686948867200:web:c8d3755ed8d6d64ebf1a65"
};

// PRODUCTION
const firebaseConfigProduction = {
  apiKey: "AIzaSyDdepBh1HH6LBPzxNzDwwTzmST1LXZ85uk",
  authDomain: "production-tailgating.firebaseapp.com",
  projectId: "production-tailgating",
  storageBucket: "production-tailgating.appspot.com",
  messagingSenderId: "391241678710",
  appId: "1:391241678710:web:53117b1662a701bf1e0457",
  measurementId: "G-0FJ4R2LW5L"
};

let firebaseConfig;
if (process.env.VUE_APP_MODE == "production") {
  firebaseConfig = firebaseConfigProduction;
}
else if (process.env.VUE_APP_MODE == "development") {
  firebaseConfig = firebaseConfigDevelopment;
}
const apps = getApps();
const firebaseApp = !apps.length ? initializeApp(firebaseConfig) : apps[0];
const firebaseAuth = getAuth(firebaseApp);
const firebaseStorage = getStorage(firebaseApp);
const firestoreDb = getFirestore(firebaseApp);
const firebaseFunctions = getFunctions(firebaseApp, "australia-southeast1");
const firebaseMessaging = getMessaging(firebaseApp);

onMessage(firebaseMessaging, (payload) => {
  console.log('Message received. ', payload);
  // Customize notification here
  // const notificationTitle = 'Background Message Title';
  // const notificationOptions = {
  //   body: 'Background Message body.',
  //   icon: '/firebase-logo.png'
  // };

  // self.registration.showNotification(notificationTitle, notificationOptions);
});

// ###################################################################################
// ################################# Database access #################################
// ###################################################################################
const getFcmTokensDocRef = (locationId) => {
  return doc(firestoreDb, "fcm_tokens", locationId);
}
const getVideoDocRef = (locationId, docId) => {
  return doc(firestoreDb, "locations", locationId, "video_data", docId);
}
const getLocationDocRef = (locationId) => {
  return doc(firestoreDb, "locations", locationId);
}
const getUserDocRef = (userId) => {
  return doc(firestoreDb, "users", userId);
}
const getMultiEntryDocRef = (selectedLocationId, datetime) => {
  const year = datetime.getFullYear().toString();
  const month = (datetime.getMonth() + 1).toString().padStart(2, '0');
  const day = datetime.getDate().toString().padStart(2, '0');
  const date_string = `${year}_${month}_${day}`;

  return doc(firestoreDb, "locations", selectedLocationId, "multi_entry", date_string);
}
const getReportDocRef = (locationId, docId) => {
  return doc(firestoreDb, "locations", locationId, "door_reports", docId);
}



const getLocationsCollectionRef = () => {
  return collection(firestoreDb, "locations");
}
const getVideoCollectionRef = (selectedLocationId) => {
  return collection(firestoreDb, `locations/${String(selectedLocationId)}/video_data`);
}
const getDoorReportCollectionRef = (selectedLocationId) => {
  return collection(firestoreDb, `locations/${String(selectedLocationId)}/door_reports`);
}
const getUsersCollectionRef = () => {
  return collection(firestoreDb, "users");
}
// ###################################################################################
// ##################################### Getters #####################################
// ###################################################################################

const isSubscriptionValid = async (locationId) => {
  const locationDetails = await getLocationDetails(locationId);
  const subscriptionStatus = locationDetails.data["subscription_status"]
  const validSubscriptions = ["active", "past_due", "trialing"] // ["active", "past_due", "unpaid", "canceled", "incomplete", "incomplete_expired", "trialing", "paused"]

  return {"isValid": validSubscriptions.includes(subscriptionStatus), "status": subscriptionStatus};
}

const getDoorId = async (locationId, doorName) => {
  const locationDocRef = getLocationDocRef(locationId);
  const docSnap = await getDoc(locationDocRef);

  if (docSnap.exists()) {
    return docSnap.data().door_name_to_id[doorName]
  }
  
  throw new Error("Could not find door id")
}

const getCurrentUser = () => new Promise((resolve, reject) => {
    const unsub = onAuthStateChanged(firebaseAuth, user => {
        unsub()
        resolve(user)
    }, reject)
})

const getCurrentUserId = () => {
  const user = firebaseAuth.currentUser;
  if (user !== null) {
    // The user object has basic properties such as display name, email, etc.
    // const displayName = user.displayName;
    // const email = user.email;
    // const photoURL = user.photoURL;
    // const emailVerified = user.emailVerified;

    return user.uid;
  }
  return null;
}

const checkAdmin = async() => {
  const customClaims = await getUserCustomClaims();
  if (customClaims) {
    return customClaims.isAdmin;
  }
  return false;
}

const getUserCustomClaims = () => {
  if(firebaseAuth.currentUser){
    return firebaseAuth.currentUser.getIdTokenResult()
    .then((idTokenResult) => {
      return idTokenResult.claims;
    })
  }
}

const getVideoCountForDate = async (selectedLocationId, date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  const dayStart = new Date(year, month - 1, day, 0, 0, 0, 0);
  const dayEnd = new Date(year, month - 1, day, 23, 59, 0, 0);

  const videoCol = getVideoCollectionRef(selectedLocationId);
  const dataQuery = query(
    videoCol, 
    where("entry_time", ">=", dayStart),
    where("entry_time", "<=", dayEnd),
    limit(10000)
  );

  const snapshot = await getCountFromServer(dataQuery);
  const totalCount = snapshot.data().count;
  return totalCount;
}
const getFlaggedVideoCountForDate = async (selectedLocationId, date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  const dayStart = new Date(year, month - 1, day, 0, 0, 0, 0);
  const dayEnd = new Date(year, month - 1, day, 23, 59, 0, 0);

  const videoCol = getVideoCollectionRef(selectedLocationId);
  const dataQuery = query(
    videoCol, 
    where("entry_time", ">=", dayStart),
    where("entry_time", "<=", dayEnd),
    where("flagged", "==", true),
    orderBy("entry_time", "desc"),
    limit(10000)
  );

  const snapshot = await getCountFromServer(dataQuery);
  const totalCount = snapshot.data().count;
  return totalCount;
}

const getVideoPath = async(entryDatetime, selectedLocationId, door, halfRange) => {
  const targetMinutes = entryDatetime.getMinutes();

  const entryDatetimeLower = new Date(entryDatetime);
  entryDatetimeLower.setMinutes(targetMinutes - halfRange);
  const entryDatetimeHigher = new Date(entryDatetime);
  entryDatetimeHigher.setMinutes(targetMinutes + halfRange);

  const gymVisitsRef = getVideoCollectionRef(selectedLocationId);
  const entryReportQuery = query(
      gymVisitsRef, 
      where("entry_time", ">=", entryDatetimeLower),
      where("entry_time", "<=", entryDatetimeHigher),
      where("door", "==", door),
      limit(1)
  )
  
  const videoQuerySnapshot = await getDocs(entryReportQuery);

  // Debugging
  // if(videoQuerySnapshot.size == 1) {
  //   console.log(`Target time: ${scanDatetime} \n\n range ${scanDatetimeLower} to ${scanDatetimeHigher}\n\n Door: ${door}`)
  // }

  let result;
  videoQuerySnapshot.forEach((resDoc) => {
    // Check if video exists in storage bucket
    if (resDoc.data().path) {
      // videos[resDoc.id] = {
      //   "path" : resDoc.data().path,
      //   "scan_time" : resDoc.data().scan_time,
      //   "door": resDoc.data().door,
      // }
      result=resDoc.data().path
    }
  });

  return result;
}

const getReportSettings = async(selectedLocationId) => {
  const locationDetails = await getLocationDetails(selectedLocationId);
  const reportSettings = locationDetails.data["report_settings"];
  // reportSettings has the following keys: range_expansion_seconds and time_offset_seconds

  return reportSettings;
}

/**
 * Get's range_expansion_seconds and time_offset_seconds from firestore location document
 * and applies it to datetimes to convert to adjusted datetimes.
 * 
 * Converts accurate time to inaccurate/offset report time
 * @param {*} selectedLocationId 
 * @param {*} startDatetime 
 * @param {*} endDatetime 
 * @param {*} reverse False: if accurate time -> inaccurate/offset report time then true;  True: if inaccurate/offset report time -> accurate time
 * @returns 
 */
const datetimedAdjustedToReportSettings = async (selectedLocationId, startDatetime, endDatetime, reverse=false) => {
  const reportSettings = await getReportSettings(selectedLocationId);

  const direction = reverse === true ? -1 : 1;
  const rangeExpansion = reportSettings["range_expansion_seconds"];
  const timeOffset = direction*reportSettings["time_offset_seconds"];
  const lookbackSeconds = 15 + 5; // TODO: !!!ATTENTION TEMP FIX

  const scanDatetimeLower = new Date(startDatetime);
  scanDatetimeLower.setSeconds(scanDatetimeLower.getSeconds() + timeOffset);
  scanDatetimeLower.setSeconds(scanDatetimeLower.getSeconds() - rangeExpansion);
  scanDatetimeLower.setSeconds(scanDatetimeLower.getSeconds() - lookbackSeconds); // TODO: !!!ATTENTION TEMP FIX

  const scanDatetimeHigher = new Date(endDatetime);
  scanDatetimeHigher.setSeconds(scanDatetimeHigher.getSeconds() + timeOffset);
  scanDatetimeHigher.setSeconds(scanDatetimeHigher.getSeconds() + rangeExpansion);

  return {
    lower: scanDatetimeLower, // i.e. start time
    upper: scanDatetimeHigher // i.e. end time
  }
}

const getEntryReports = async(startDatetime, endDatetime, selectedLocationId) => {
  const adjustedDatetimes = await datetimedAdjustedToReportSettings(selectedLocationId, startDatetime, endDatetime);
  const gymVisitsRef = getDoorReportCollectionRef(selectedLocationId);
  const entryReportQuery = query(
      gymVisitsRef, 
      where("scan_time", ">=", adjustedDatetimes.lower),
      where("scan_time", "<=", adjustedDatetimes.upper),
      // where("door_id", "==", doorId),   // V2 HandlingError: yes filters for specific door
      orderBy("scan_time", "desc"),
      limit(1000)
  )

  const entryReportQuerySnapshot = await getDocs(entryReportQuery);

  const suspectIds = [];
  let suspects = {}
  entryReportQuerySnapshot.forEach((resDoc) => {
    const name = resDoc.data().username
    suspects[resDoc.data().scan_time] = {
        "name" : resDoc.data().username,
        "scan_time": resDoc.data().scan_time,
    }

    if (!suspectIds.includes(name)){
      suspectIds.push(name);
    }
  });

  return {
    names: suspectIds,
    suspects: suspects,
  };
}

// Get's all locations available to FusorLabs
const getLocations = async() => {
  let validResult = false;
  const videoRef = getLocationsCollectionRef();
  
  const locationQuery = query(videoRef, limit(1000));
  const locationQuerySnapshot = await getDocs(locationQuery)

  const locations = {}
  locationQuerySnapshot.forEach((resDoc) => {
      locations[resDoc.data().name] = resDoc.id
      validResult = true
  });

  if (validResult){
    return locations
  }
  return null
}

// Get's all locations for a specific client. Assum max 1000 locations per client
const getClientLocations = async() => {
  const customClaims = await getUserCustomClaims()
  let locations = {};
  if (customClaims) {
    if (customClaims.location_permissions) {
      const clientLocations = Object.keys(customClaims.location_permissions);

      for (const locationId of clientLocations) {
        const locationDocRef = getLocationDocRef(locationId);
        const docSnap = await getDoc(locationDocRef);

        if (docSnap.exists()) {
          locations[docSnap.data().name] = locationId
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
      }      
    }
  }

  return locations;
}

const getLocationMembers = async(locationId) => {
  const usersCollectionRef = getUsersCollectionRef();

  const usersQuery = query(
    usersCollectionRef,
    or(
      where(`custom_claims.location_permissions.${locationId}`, "==", 'user'),
      where(`custom_claims.location_permissions.${locationId}`, "==", 'owner'),
    ),
    limit(100)
  );

  const videoQuerySnapshot = await getDocs(usersQuery);

  let users = [];
  videoQuerySnapshot.forEach((doc) => {
    const userInfo = {}
    userInfo['id'] = doc.id
    userInfo['name'] = doc.data().name
    userInfo['email'] = doc.data().email
    userInfo['role'] = doc.data().custom_claims.location_permissions[locationId]
    users.push(userInfo)
  })

  return users;
}

const getLocationDetails = async(locationId) => {
  try {
    const docRef = getLocationDocRef(locationId);
    const docSnap = await getDoc(docRef);

    let data = {};
    if (docSnap.exists()) {
      data["locationId"] = docSnap.id;
      data["subscription_status"] = docSnap.data().subscription_status;
      data["name"] = docSnap.data().name;
      data["address"] = docSnap.data().address;
      data["franchise"] = docSnap.data().franchise;
      data["door_id_to_areas"] = docSnap.data().door_id_to_areas;
      data["door_name_to_id"] = docSnap.data().door_name_to_id;
      data["report_settings"] = docSnap.data().report_settings;
      
      return {
        data,
      };
    } else {
      // docSnap.data() will be undefined in this case
      return null;
    }   
  }
  catch {
    return null
  }
}

const getUserDetailsFromEmail = async(userEmail) => {
  const usersCollectionRef = getUsersCollectionRef()
  const userQuery = query(  usersCollectionRef,
                            where("email", "==", userEmail),
                            limit(10)
                          );
  const userQuerySnapshot = await getDocs(userQuery)
  if (userQuerySnapshot.size > 1){
    throw new Error("Multiple user ids found for user email")
  }
  const userInfo = {}
  userQuerySnapshot.forEach((resDoc) => {
      userInfo['id'] = resDoc.id
      userInfo['name'] = resDoc.data().name
      userInfo['email'] = resDoc.data().email
      userInfo['custom_claims'] = resDoc.data().custom_claims
  });
  return userInfo;
}

const getUserDetails = async(userId) => {
  const docRef = getUserDocRef(userId);
  const docSnap = await getDoc(docRef);

  let userInfo = {};
  if (docSnap.exists()) {
    userInfo['id'] = doc.id
    userInfo['name'] = doc.data().name
    userInfo['email'] = doc.data().email
    userInfo['custom_claims'] = doc.data().custom_claims
    return userInfo;
  } else {
    // docSnap.data() will be undefined in this case
    return null;
  }
}

// ###################################################################################
// ##################################### Setters #####################################
// ###################################################################################

const createNewLocation = async(name, franchise, areas, door_names, address, reportSettings) => {
  console.log('creating location with: ')
  console.log(`name ${name}`)
  console.log(`franchise ${franchise}`)
  console.log(`areas ${areas}`)
  console.log(`door_names ${door_names}`)
  console.log(`address ${address}`)
  console.log(`reportSettings ${reportSettings}`)

  const docRef = await addDoc(getLocationsCollectionRef(), {
    name: name,
    franchise: franchise,
    door_id_to_areas: areas,
    door_name_to_id: door_names,
    address: {
      line1: address.line1,
      city: address.city,
      region: address.region,
      postalCode: address.postalCode,
      country: address.country
    },
    report_settings: {
      range_expansion_seconds: reportSettings.rangeExpansion,
      time_offset_seconds: reportSettings.timeOffset,
    }
  });
  return docRef.id
}

const addUserLocation = async(uid, locationId, role) => {
  const userRef = getUserDocRef(uid);

  // Atomically add a new region to the "regions" array field.
  await updateDoc(userRef, {
      [`custom_claims.location_permissions.${locationId}`]: role,
      // locations: arrayUnion(locationId)
  });
}

const addReportToVideo = async(locationId, vidId, suspectDetails) => {
  const vidRef = getVideoDocRef(locationId, vidId)
  const cardNumber = suspectDetails.card_number;

  await updateDoc(vidRef, {
    [`suspects.${suspectDetails.scan_time}`]: suspectDetails,
    suspects_card_numbers: arrayUnion(cardNumber),
  })
}

/**
 * 
 * @param {*} docRef 
 * @param {number} suspectCardNumber 
 * @param {boolean} confirmStatus true/false
 */
const confirmMultiEntrySuspect = async (docRef, suspectCardNumber, confirmStatus)  => {
  if (confirmStatus) {
    await updateDoc(docRef, {
      [`user_reviewed.${suspectCardNumber}`]: true
    });    
  } 
  else {
    await updateDoc(docRef, {
      [`suspects.${suspectCardNumber}`]: deleteField(),
      [`user_reviewed.${suspectCardNumber}`]: true
    });   
  }

}

const updateVideoDocMultiEntryStatus = async (locationId, vidIdArray, status) => {
  for (const vidDocId of vidIdArray) {
    const vidDocRef = getVideoDocRef(locationId, vidDocId);
    await updateDoc(vidDocRef, {
      'is_multi_entry': status,
      'user_reviewed_multi_entry': status
    });
  }
}

// For sending push notifications
const updateRegistrationToken = async(uid, locationIdArr, token) => {
  locationIdArr.forEach( async (locationId) => {
    const fcmTokensRef = getFcmTokensDocRef(locationId);

    await setDoc(
      fcmTokensRef, 
      {
        [`${token}`]: {
          last_updated: Timestamp.fromDate(new Date()),
          user: uid,
        },
      },
      {
        merge: true
      }
    );

  })

}

// ############################################################################################
// ##################################### Firebase Storage #####################################
// ############################################################################################

const getFirebaseUrl = async (path) => {
  const videoRef = ref_storage(firebaseStorage, path);
  const url = getDownloadURL(videoRef)
  .then((url) => {return url;})
  .catch(() => {
      // console.error('Error getting video download URL:', error);
      return null;
    });

  return url
}

export {  firebaseApp, 
          firebaseAuth, 
          firebaseStorage, 
          firestoreDb, 
          firebaseFunctions,
          firebaseMessaging,

          isSubscriptionValid,
          getDoorId,
          getCurrentUser,
          getCurrentUserId,
          checkAdmin,
          getUserCustomClaims,
          getVideoCountForDate,
          getFlaggedVideoCountForDate,
          getVideoPath,
          datetimedAdjustedToReportSettings,
          getEntryReports,
          getLocations, 
          getClientLocations,
          getLocationMembers,
          getLocationDetails,
          getUserDetailsFromEmail,
          getUserDetails,

          createNewLocation,
          addUserLocation,
          addReportToVideo,

          getVideoDocRef,
          getLocationDocRef,
          getUserDocRef,
          getMultiEntryDocRef,
          getReportDocRef,

          getLocationsCollectionRef,
          getVideoCollectionRef,
          getDoorReportCollectionRef,
          getUsersCollectionRef,

          getReportSettings,

          confirmMultiEntrySuspect,
          updateVideoDocMultiEntryStatus,
          updateRegistrationToken,

          getFirebaseUrl,
        }