<template>
  <div>
    <AdminNavBar />
    <div class="flex flex-col items-center">
      <button
          @click="nextStage"
          class="p-2 mt-4 text-white underline rounded hover:bg-blue-700">
          Skip
      </button>

      <NewAccountForm v-if="currentStage==1" @submitted="onAccountCreated"></NewAccountForm>
      <NewLocationForm v-if="currentStage==2" @submitted="onLocationCreated"></NewLocationForm>

      <div  v-if="currentStage==3"
            class="flex flex-col w-fit mt-5 items-center">
        <label for="role" class="mb-2 text-sm font-medium">User Role (owner, user):</label>
        <input v-model="userRole" type="text" id="role" required class="p-2 border border-gray-300 rounded text-black" />
        <button 
          @click="linkUserLocation"
          class="p-2 mt-4 bg-blue-500 text-white rounded hover:bg-blue-700">
          Link User and Location
        </button>
      </div>

      <h1 v-if="currentStage==4">Done!</h1>
      <div class="w-8/12 bg-gray-200 rounded overflow-hidden m-5">
        <div class="bg-blue-500 h-4 text-xs leading-none py-1 text-center text-white" :style="{ width: progressBarWidth }"></div>
      </div>

    </div>



  </div>
</template>

<script setup>
import AdminNavBar from "@/components/AdminNavBar";
import NewAccountForm from  '@/components/adminTools/NewAccountForm.vue'
import NewLocationForm from  '@/components/adminTools/NewLocationForm.vue'

import { addUserLocation } from "@/firebase"
import { ref, computed } from 'vue';
const currentStage = ref(1);

const onAccountCreated = (submittedUid) => {
  uid.value = submittedUid;
  currentStage.value++;
}

const onLocationCreated = (submittedLocationId) => {
  locationId.value = submittedLocationId;
  currentStage.value++;
}

const uid = ref('');
const locationId = ref('');
const userRole = ref('');
const linkUserLocation = async () => {
  await addUserLocation(uid.value, locationId.value, userRole.value);
  currentStage.value++;
}

const nextStage = () => {
  currentStage.value++;
  if (currentStage.value > 4) {
    currentStage.value = 1
  }
}
const progressBarWidth = computed(() => {
  const stagePercentage = 33.3333; // Approximately one third for each stage
  return `${stagePercentage * currentStage.value}%`; // This will result in a string, e.g., "33.3333%", "66.6666%", "100%"
});

</script>