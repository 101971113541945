<template>
  <div>
    <h1 class="font-bold pb-2">Sign In</h1>
    <form class="space-y-4" @submit.prevent="emailSignIn">
      <div class="flex flex-col">
        <label for="email" class="mb-2 text-sm font-medium">Email:</label>
        <input v-model="email" type="email" id="email" required class="p-2 border border-gray-300 rounded text-black" />
      </div>
      <div class="flex flex-col">
        <label for="password" class="mb-2 text-sm font-medium">Password:</label>
        <input v-model="password" type="password" id="password" required class="p-2 border border-gray-300 rounded text-black" />
      </div>
      <button type="submit" class="p-2 mt-4 bg-blue-500 text-white rounded hover:bg-blue-700">Sign In</button>
    </form>

    <!-- Google SignIn disabled -->
    <div class="flex flex-col space-y-4 mt-8">
      <button @click="googleSignIn" class="p-2 bg-white text-black rounded hover:bg-red-700 hover:text-white flex items-center space-x-2">
        <img src="../../assets/google.png" alt="Google logo" class="w-5 h-5"/>
        <span>Sign in with Google</span>
      </button>
    </div>

    <h1>Don't have an account? Contact us to sign up</h1>
  </div>
</template>

<script>
import {getAuth, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, signOut} from 'firebase/auth'
import { useRouter } from 'vue-router'
import { ref } from 'vue';
import {
  // collection,
  getDocs,
  where,
  query,
  limit,
} from "firebase/firestore";
import {  checkAdmin,
          getUsersCollectionRef, 
          // getLocationsCollectionRef 
} from "@/firebase";

export default {
  components: { },
  setup() {
    const router = useRouter();
    const isRegistered = ref(false);

    const googleSignIn = async () => {
      const provider = new GoogleAuthProvider()
      const auth = getAuth();

      const result = await signInWithPopup(auth, provider)
      // This gives you a Google Access Token. You can use it to access the Google API.
      GoogleAuthProvider.credentialFromResult(result);
      // Registration Check
      const user = result.user;
      await signInFlow(user);      
    }

    const email = ref('');
    const password = ref('');
    const emailSignIn = async() => {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, email.value, password.value)
        .then(async (userCredential) => {
          const user = userCredential.user
          await signInFlow(user)
          router.replace({ name: 'Inbox' })
        })
        .catch((error) => {
          console.log(error);
        });
    }

    const signInFlow = async (user) => {
      // Firestore users
      const usersRef = getUsersCollectionRef();
      const usersQuery = query(usersRef, where("email", "==", user.email), limit(1));
      const usersQuerySnap = await getDocs(usersQuery);

      try {
        if (usersQuerySnap.size === 0) {
          // User is not registered, so sign them out
          const auth = getAuth();
          await signOut(auth);
          console.log('User is not registered');
          // !!! ATTENTION put a modal here to ask users to contact FusorLabs to discuss a tailored solution for their business
        } 
        
        else {
          await prepareUserHome(user.uid);
        }
      }
      catch (error) {
        console.log(error);
      }
    }

    const prepareUserHome = async () => {
      try {
        // User is registered, so continue
        isRegistered.value = true;

        //Moving to next page
        if(await checkAdmin()){
          router.replace({ name: 'AdminHome' });
        }
        else {
          router.replace({ name: 'Inbox' });
        }
      }
      catch (error) {
        console.log(error);
      }
    }

    return {
      googleSignIn,
      email,
      password,
      emailSignIn,
    }
  },
}
</script>