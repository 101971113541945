<template>
  <div v-if="showSuspect" class="bg-blue-950 mt-5">
    <!-- <div v-for="suspectVideosItem, index in visibleGridData" :key="suspectVideosItem.card_number"> -->
    <div class="bg-slate-500 bg-opacity-25 p-5 m-10">
      <h1 class="text-2xl mb-7 font-bold underline">{{suspectId}}</h1>
      <div class="grid-container">
        <div v-for="item in suspectVideosItem" :key="item.vid_id">
          <div class="grid-item bg-transparent p-0">
            <!-- Raw vid size: 3072 x 1728 -->
            <VideoPlayer
            :vidPath="item.vid_path"
            :metadata="item"
            :width="width" 
            :height="height"
            ></VideoPlayer>
            
            <CardInfoTable
            :suspects="item.suspects"
            :showTip="false"
            ></CardInfoTable>
          </div>
        </div>
      </div>

      <HandleResponse
      v-if="!confirmed"
      message="Unauthorised Entry?"
      :handleYes="handleYes"
      :handleNo="handleNo"
      ></HandleResponse>

    </div>

  </div>
</template>

<script>


import { ref, onMounted} from "vue";
import { useStateStore } from '@/stores/StateStore';
import {
  getDoc,
} from "firebase/firestore";
import {getMultiEntryDocRef} from "@/firebase";

import CardInfoTable from '../CardInfoTable.vue';
import VideoPlayer from '../VideoPlayer.vue';

import {confirmMultiEntrySuspect, updateVideoDocMultiEntryStatus} from "@/firebase";
import HandleResponse from '../HandleResponse.vue';

export default {
  props: ["index", "multiEntryDocRef", "suspectId", "suspectVideosItem", "multiEntryReviewed", "removeGridFromView", "date", "width", "height"],
  components: {
    CardInfoTable,
    VideoPlayer,
    HandleResponse,
},
  setup(props) {
    const stateStore = useStateStore();
    const selectedLocationId = ref();
    selectedLocationId.value = stateStore.userLocations[stateStore.selectedLocation];

    const confirmed = ref(false);
    onMounted(async () => {
      const multiEntryDocRef = getMultiEntryDocRef(selectedLocationId.value, props.date);
      const multiEntryDocSnap = await getDoc(multiEntryDocRef);
      
      if (multiEntryDocSnap.exists()) {
        const multiEntryReviewed = multiEntryDocSnap.data().user_reviewed;
        confirmed.value = multiEntryReviewed[props.suspectId]
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    });

    const showSuspect = ref(true);
 
    const handleYes = async () => {
      // Update multi entry doc
      await confirmMultiEntrySuspect(props.multiEntryDocRef, props.suspectId, true);

      // Update all the corresponding video docs
      const suspectEntries = props.suspectVideosItem;
      const vidDocIdArray = []
      for (const timestampKey of Object.keys(suspectEntries)) {
        vidDocIdArray.push(suspectEntries[timestampKey].vid_id);
      }
      await updateVideoDocMultiEntryStatus(selectedLocationId.value, vidDocIdArray, true);

      confirmed.value = true;
    }
    const handleNo = async () => {
      // Update multi entry doc
      await confirmMultiEntrySuspect(props.multiEntryDocRef, props.suspectId, false);

      // Update all the corresponding video docs
      const suspectEntries = props.suspectVideosItem;
      const vidDocIdArray = []
      for (const timestampKey of Object.keys(suspectEntries)) {
        vidDocIdArray.push(suspectEntries[timestampKey].vid_id);
      }
      await updateVideoDocMultiEntryStatus(selectedLocationId.value, vidDocIdArray, false);

      // Remove from view
      // props.removeGridFromView(props.suspectId);
      showSuspect.value = false;

      confirmed.value = true;
    }

    return {
      handleYes,
      handleNo,
      confirmed,
      showSuspect,
    }
  }
};
</script>

<style>
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  text-align: center;
}
@media (max-width: 1050px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
