<template>
  <div class="flex flex-col items-center">

    <!-- Yes / No Buttons -->
    <div v-if="!confirmed">
      <div class="space-x-2 mt-3 mb-12">
        <h6> {{ message }} </h6>
        <button @click="onYesClick"
                class="bg-emerald-600 hover:bg-emerald-800 text-white font-bold py-1 px-4 rounded">
                Yes
        </button>
        <button @click="onNoClick"
                class="bg-rose-600 hover:bg-rose-800 text-white font-bold py-1 px-4 rounded">
                No
        </button>
        
        <div v-if="!confirmed && recordingResponse" type="button" class="bg-transparent font-semibold py-2 px-4 rounded flex flex-row justify-center w-fit" disabled>
          <svg class="animate-spin h-5 w-5 mr-3 ..." fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg" >
              <path d="M10 3C6.13401 3 3 6.13401 3 10C3 10.2761 2.77614 10.5 2.5 10.5C2.22386 10.5 2 10.2761 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C9.72386 18 9.5 17.7761 9.5 17.5C9.5 17.2239 9.72386 17 10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3Z" fill="#18ffff"/>
          </svg>
          <span class="text-cyan-200">Loading...</span>
        </div>
        
      </div>
    </div>
   
  </div>

</template>

<script>
import { ref } from "vue";

export default {
  props: ['message', 'handleYes', 'handleNo'],
  setup(props) {
    const recordingResponse = ref(false);
    const confirmed = ref(false);

    const onYesClick = () => {
      recordingResponse.value = true;

      props.handleYes();

      recordingResponse.value = true;
      confirmed.value = true;
    }
    
    const onNoClick = () => {
      recordingResponse.value = true;

      props.handleNo();

      recordingResponse.value = true;
      confirmed.value = true;
    }

    return {
      onYesClick,
      onNoClick,
      recordingResponse,
      confirmed,
    }
  }
};
</script>