function binarySearchForInsertion(arr, target, compareFunc) {
    let left = 0;
    let right = arr.length - 1;

    while (left <= right) {
        let mid = Math.floor((left + right) / 2);

        // Compare datetime values using the comparison function
        let comparisonResult = compareFunc(arr[mid], target);

        if (comparisonResult < 0) {
            left = mid + 1;
        } else {
            right = mid - 1;
        }
    }

    // At the end of the loop, 'left' indicates the index where the new element should be inserted
    return left;
}

// Comparison function to compare datetime values
function compareFirestoreTimestamps(obj1, obj2) {
    return obj1.entry_time.toDate() - obj2.entry_time.toDate();
}

function insertFlaggedVid(sortedArray, newObj) {
    // Find the index where the new object should be inserted
    const insertIndex = binarySearchForInsertion(sortedArray, newObj, compareFirestoreTimestamps);
    sortedArray.splice(insertIndex, 0, newObj);
}

function getDatetimeBeforeAndAfter(dateTime, secondsHalfRange) {
    // Clone the input datetime to avoid modifying the original
    const beforeDateTime = new Date(dateTime);
    const afterDateTime = new Date(dateTime);

    // Calculate 5 minutes in milliseconds
    const millisecondsHalfRange = secondsHalfRange * 1000;

    // Adjust before and after datetime objects
    beforeDateTime.setTime(beforeDateTime.getTime() - millisecondsHalfRange);
    afterDateTime.setTime(afterDateTime.getTime() + millisecondsHalfRange);

    return { before: beforeDateTime, after: afterDateTime };
}

export {
    insertFlaggedVid,
    getDatetimeBeforeAndAfter,
}