import { defineStore } from 'pinia';
import { useLocalStorage } from "@vueuse/core"

export const useStateStore = defineStore('stateStore', {
    state: () => ({
        selectedLocation: useLocalStorage('selectedLocation', null),
        userLocations: useLocalStorage('userLocations', {}),
        playbackSpeed: useLocalStorage('playbackSpeed', "1"),
        notificationTokenLastUpdated: useLocalStorage('notificationTokenLastUpdated', {}),
    }),
    actions: {
        appendToUserLocations(name, locationId) {
            this.userLocations[name] = locationId
        },
        setUserLocations(locationsObject){
            this.userLocations = locationsObject;
        },
        clearStore(){
            this.zone = 0;
            this.selectedLocation = null;
            this.userLocations = {};
        },
        getSelectedLocationId(){
            return this.userLocations[this.selectedLocation];
        },
        updateNotificationToken(uid){
            const currDate = new Date();
            this.notificationTokenLastUpdated[uid] = currDate.toString();
        },
        lastUpdateNotificationToken(uid){
            try {
                const lastUpdated = new Date(this.notificationTokenLastUpdated[uid]);
                if (!isNaN(lastUpdated)) {
                    return lastUpdated
                }
                else {
                    return false;
                }
            }
            catch {
                return false;
            }

        },
    },  
})

